import React, { useEffect, useState, useCallback } from "react"
import * as styles from "./list.module.css"
import Pagenation from "components/tools/pagination.js"
import Layout from "components/common/layout.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getCategoryListAsync } from "store/modules/categoryReducer.js"
import { storyCategory } from "utils/data.js"
import Story from "components/story/story"
import OrderMenu from "components/tools/orderMenu"
import categoryCommunication from "images/categoryCommunication.png"
import categoryKnowledge from "images/categoryKnowledge.png"
import categoryLibrary from "images/categoryLibrary.png"
import categoryNews from "images/categoryNews.png"
import categoryOverall from "images/categoryOverall.png"
import categoryPersonal from "images/categoryPersonal.png"
import { useMenu } from "hooks"
import queryString from "query-string"
import { navigate } from "gatsby"

const List = props => {
  const dispatch = useDispatch()
  const getCategoryList = useCallback(
    (currentPage, pagePostSize, order, mainparam, sub) =>
      dispatch(
        getCategoryListAsync(currentPage, pagePostSize, order, mainparam, sub)
      ),
    [dispatch]
  )

  const { categoryList, total } = useSelector(
    ({ categoryReducer }) => categoryReducer,
    shallowEqual
  )
  const [order, setOrder] = useState(1)
  const { page } = queryString.parse(props.location.search)
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1)

  const [subjectNum, setSubjectNum] = useState(0)
  const [mainNum, setMainNum] = useState(0)
  const [title, setTitle] = useState("스토리 전체 보기")

  useEffect(() => {
    const state = props.location.state
    const mainKey = state?.mainKey
    const subKey = state?.subKey
    console.log(state)
    let id = false

    if (mainKey) {
      id = `mainClicked_${mainKey}`
      document.getElementById(`${id}`).click()
    } else if (subKey) {
      document
        .getElementById(`mainClicked_${Math.round(subKey / 10) * 10}`)
        .click()
      id = `subClicked_${subKey}`
    } else {
      id = "mainClicked_0"
    }

    if (id) document.getElementById(`${id}`).click()
  }, [props])

  useEffect(() => {
    ;(async () => {
      console.log(subjectNum)
      getCategoryList(currentPage, pagePostSize, order, mainNum, subjectNum)
    })()
  }, [order, currentPage, subjectNum, mainNum])

  const [, setMain, onMain] = useMenu()
  const [selectSub, setSub, onSub] = useMenu()

  const onMainMenu = ({ currentTarget }, idx) => {
    const text = idx === 0 ? "스토리 전체 보기" : main.get(idx)
    setTitle(text)
    setSubjectNum(0)
    setMainNum(idx)
    setCurrentPage(1)
    //
    if (selectSub.current) {
      selectSub.current.style.color = "#333333"
      selectSub.current = null
    }

    const setter = (tag, style) => {
      const [main, sub] = tag.children
      const [imgBox, title] = main.children

      title.style.color = style.color
      imgBox.style.backgroundColor = style.backgroundColor
      sub.style.display = style.display
    }

    setMain(currentTarget)

    onMain(curr => {
      setter(curr, {
        color: "#e95c63",
        backgroundColor: "#fceaea",
        display: "flex",
      })

      return prev => {
        setter(prev, {
          color: "#333333",
          backgroundColor: "#fafafa",
          display: "none",
        })
      }
    })
  }

  const onSubMenu = (e, idx) => {
    e.stopPropagation()
    setTitle(sub.get(idx))
    setMainNum(0)
    setSubjectNum(idx)
    setCurrentPage(1)
    //
    setSub(e.currentTarget)
    onSub(curr => {
      curr.style.color = "#e95c63"
      return prev => (prev.style.color = "#333333")
    })
  }

  return (
    <Layout>
      <div>
        <div className={"title"} style={{ marginTop: "60px" }}>
          <p className={`subtitle`}>{title}</p>
          <p className={styles.titleIntro}>{titleIntroText[title]}</p>
          <div>
            (전체
            <p className={`red subnotice`}>{total}</p>개 스토리)
          </div>
        </div>

        <div className={styles.menuImg}>
          {menuImg.map((img, i) => {
            const idx = i * 100
            const isAll = idx == 0
            const sub_menu_length = isAll ? [] : [1, 2, 3]

            return (
              <div
                onClick={e => {
                  onMainMenu(e, idx)
                }}
                className={`${idx}`}
                id={`mainClicked_${idx}`}
              >
                <div>
                  <div>
                    <img src={img} />
                  </div>
                  <p>{isAll ? "전체" : main.get(idx)}</p>
                </div>

                <div className={`subMenu_${idx}`}>
                  {sub_menu_length.map(v => (
                    <p
                      onClick={e => {
                        onSubMenu(e, idx + v)
                      }}
                      id={`subClicked_${idx + v}`}
                      name={"subMenuName"}
                    >
                      {sub.get(idx + v)}
                    </p>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
        <OrderMenu
          order={order}
          setOrder={setOrder}
          style={{ justifyContent: "flex-start" }}
          setCurrentPage={setCurrentPage}
        />

        <div className={`colContainer ${styles.main}`}>
          {categoryList.map(post => {
            return (
              <div key={post.story_no}>
                <Story
                  story_no={post.story_no}
                  title={post.title}
                  introText={post.introText}
                  thumb_title_uuid={post.thumb_title_uuid}
                  cateMain={post.cateMain}
                  cateSub={post.cateSub}
                  Like={post.Like}
                  free={post.free}
                  registerTime={post.publishTime}
                />
              </div>
            )
          })}
        </div>
        <div style={{ paddingTop: "50px" }}>
          <Pagenation
            total={total}
            pagePostSize={pagePostSize}
            pageSize={10}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            Nav={true}
          />
        </div>
      </div>
    </Layout>
  )
}

export default List
const menuImg = [
  [categoryOverall],
  [categoryCommunication],
  [categoryKnowledge],
  [categoryNews],
  [categoryLibrary],
  [categoryPersonal],
]
const pagePostSize = 16
const { main, sub } = storyCategory

const titleIntroText = {
  소통: "나와 다른 사람들이 함께 살아가는 이야기 ",
  감정이해: "감정과 마음을 알아보는 이야기",
  사회성: "다른 사람과 잘 지내기 위한 방법",
  언어: "어려운 말을 쉽게 알아보는 이야기",
  지식: "읽고 나면 세상이 달라 보이는 이야기",
  역사: "옛날에 실제로 있었던 사건",
  과학: "쉽게 알아보는 과학 원리",
  사회: "우리 사회를 이해할 때 필요한 지식",
  뉴스: "최근에 일어난 사건, 사고",
  사회: "정치, 경제, 사회 뉴스 ",
  국제: "세계 여러 나라의 사건, 사고 뉴스",
  문화: "요즘 사람들의 문화생활 뉴스",
  문학: "고전 명작부터 최근 작품까지 쉽게 보는 이야기",
  그림책: "그림과 함께 읽는 이야기",
  "시 · 에세이": "생각과 경험을 아름답게 표현한 글",
  소설: "쉽게 읽는 유명한 소설",
  자립: "스스로 살아가는 방법 이야기",
  여가: "다양한 취미 생활 이야기",
  생활정보: "생활이 편해지는 꿀팁",
  진로: "취업 준비와 직장 생활 이야기",
}
