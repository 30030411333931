import React, { useState, useEffect } from "react"
import * as styles from "components/common/layout.module.css"
import Header from "./header"
import Footer from "./footer"
import { getRank } from "utils"

const Layout = props => {
  //headerMenu ( full - 정회원, associate - 준회원, none - 비회원)
  // const [memberType, setMemberType] = useState(null)
  console.log(globalThis.innerWidth)

  return (
    <div className={styles.container}>
      <Header
        rank={getRank()}
        settingHeader={props.settingHeader}
        navCancel={props.navCancel}
        payment={props.payment}
        style={props.headerStyle}
      />
      <div className={styles.container}>
        <div>{props.children}</div>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
