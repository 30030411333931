import { navigate } from "gatsby"
import React from "react"
import { orderTable } from "utils/data"
import * as styles from "./orderMenu.module.css"
const OrderMenu = props => {
  return (
    <div className={styles.menu} style={props.style}>
      {Array.from(orderTable).map(([key, value]) => (
        <div
          key={key}
          data-order={key}
          className={props.order === key ? styles.on : ""}
          onClick={({
            target: {
              dataset: { order },
            },
          }) => {
            if (props.setCurrentPage) {
              props.setCurrentPage(1)
            }
            props.setOrder(Number(order))
          }}
        >
          {value}
        </div>
      ))}
    </div>
  )
}

export default OrderMenu
