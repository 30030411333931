// extracted by mini-css-extract-plugin
export var head = "header-module--head--3f7xF";
export var con = "header-module--con--JZvRi";
export var main = "header-module--main--1nwIt";
export var footer = "header-module--footer--2pXTr";
export var storyCon = "header-module--storyCon--hmxhe";
export var mainClass = "header-module--mainClass--1lEOA";
export var subClass = "header-module--subClass--3fyrV";
export var classCon = "header-module--classCon--2rzAk";
export var hr = "header-module--hr--2MsM7";
export var settingCon = "header-module--settingCon--3klRC";
export var alarm = "header-module--alarm--3V-06";
export var conAlarm = "header-module--conAlarm--uAVDu";