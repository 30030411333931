import React, { useState, Fragment, useEffect, useRef } from "react"
import * as styles from "components/common/header.module.css"
import Logo from "components/tools/logo.js"
import SearchContainer from "containers/searchContainer"
import { storyCategory } from "utils/data"
import { navigate } from "gatsby"
import fontBookShelf from "images/fontBookShelf.png"
import SpeechBubble from "components/tools/speechBubble"
import Menu from "components/menu"
import Band from "components/tools/banner/Band"
import { getRank, permission } from "utils"
import { rank as memberRank } from "utils/data"
import { shallowEqual, useSelector } from "react-redux"
const Header = props => {
  //headerMenu ( full - 정회원, associate - 준회원, none - 비회원)
  //
  const style = props.style ?? {
    minWidth: "1100px",
    maxWidth: "1100px",
    margin: "auto",
  }
  const [inBubble, setInBubble] = useState(null)
  const [location, setLocation] = useState(null)
  const locationRef = useRef()
  const [vertexPos, setVertexPos] = useState(null)

  const { main, sub } = storyCategory

  const onCategory_ = ({ target }) => {
    const display =
      target.getAttribute("name") === "category" ? "block" : "none"
    categoryRef.current.style.display = display
  }

  const alarms = useSelector(
    ({ alarmReducer: { alarm } }) => alarm,
    shallowEqual
  )
  const alarmList = alarms.list.length
  const categoryRef = useRef()
  return (
    <>
      {props.payment ? (
        <div
          style={{
            minWidth: "1800px",
            maxWidth: "1800px",
            margin: "auto",
          }}
        >
          <div style={{ margin: "40px 0px" }}>
            <Logo w={188.15} h={41.43} navCancel={props.navCancel} />
          </div>
        </div>
      ) : props.settingHeader ? (
        <div style={style}>
          <div style={{ margin: "40px 0px" }}>
            <Logo w={188.15} h={41.43} navCancel={props.navCancel} />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.con}>
            <Band />
            <div className={styles.main}>
              <div className={styles.head}>
                <div onClick={() => navigate(`/front`)}>
                  <Logo w={121.94} h={25.85} style={style} />
                </div>

                <div>
                  <SearchContainer />
                </div>
                <div>
                  <Menu
                    locationRef={locationRef}
                    setLocation={setLocation}
                    settingStyle={{
                      width: "86px",
                      padding: "10px",
                      left: getRank() === memberRank.NORMAL ? "241px" : "167px",
                      top: "35px",
                    }}
                    alarmStyle={{
                      width: "297px",
                      padding: "0px",
                      height: `${alarmList * 102.5}px`,
                      minHeigth: "100px",
                      maxHeight: "720px",
                      right: "-1.3vw",
                      top: "35px",
                    }}
                  />
                </div>
              </div>
              <div className={styles.footer} ref={locationRef}>
                <div>
                  <button
                    id="storys"
                    onClick={() => {
                      location == "story"
                        ? setLocation(null)
                        : setLocation("story")

                      setInBubble(
                        <div style={{ cursor: "pointer" }}>
                          <div className={styles.storyCon}>
                            <div>
                              <a
                                onClick={() => {
                                  navigate("/story/interest/list")
                                }}
                                className={"subMenu_1"}
                              >
                                관심주제
                              </a>
                              <div
                                onClick={onCategory_}
                                className={"subMenu_2"}
                                name="category"
                              >
                                카테고리
                              </div>
                              <a
                                onClick={() => {
                                  navigate("/news/list")
                                }}
                                className={"subMenu_3"}
                              >
                                기획전
                              </a>
                            </div>
                            <div className={styles.mainClass} ref={categoryRef}>
                              <hr className={styles.hr} />
                              <div>
                                {Array.from(main).map(([key, value], i) => (
                                  <a
                                    key={key}
                                    style={{ color: `${mainColor[i]}` }}
                                    onClick={() => {
                                      navigate("/story/category/list", {
                                        state: {
                                          mainKey: key,
                                        },
                                      })
                                    }}
                                  >
                                    {value}
                                  </a>
                                ))}
                              </div>
                              <div className={styles.subClass}>
                                {/* {(() => {
                                const height_length = 3
                                const width_length = sub.size / height_length

                                const sub_menulist = []
                                let initial = 0

                                for (let i = 0; i < width_length; i++) {
                                  const begin = initial
                                  const end = initial + height_length
                                  initial = end
                                  sub_menulist.push(
                                    <div>
                                      {Array.from(sub)
                                        .slice(begin, end)
                                        .map(([key, val]) => (
                                          <a
                                            key={key}
                                            onClick={() => {
                                              navigate("/story/category/list")
                                            }}
                                          >
                                            {val}
                                          </a>
                                        ))}
                                    </div>
                                  )
                                }
                                return sub_menulist
                              })()} */}
                                {Array.from(main.keys()).map(
                                  (mainKey, i, main) => {
                                    return (
                                      <div key={i}>
                                        {Array.from(sub).reduce(
                                          (initial, [key, value]) => {
                                            let flag = mainKey < key

                                            if (main[i + 1]) {
                                              flag = flag && key < main[i + 1]
                                            }

                                            if (flag) {
                                              initial.push(
                                                <a
                                                  key={key}
                                                  onClick={() => {
                                                    navigate(
                                                      "/story/category/list",
                                                      {
                                                        state: {
                                                          subKey: key,
                                                        },
                                                      }
                                                    )
                                                  }}
                                                >
                                                  {value}
                                                </a>
                                              )
                                            }

                                            return initial
                                          },
                                          []
                                        )}
                                      </div>
                                    )
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )

                      setVertexPos("35px")
                    }}
                  >
                    스토리
                  </button>

                  <span>|</span>

                  <button
                    id="class"
                    onClick={() => {
                      location == "class"
                        ? setLocation(null)
                        : setLocation("class")

                      setInBubble(
                        <div style={{ cursor: "pointer" }}>
                          <div className={styles.classCon}>
                            <div style={{ marginLeft: "20px" }}>
                              <a style={{ width: "80px" }}></a>
                              <a
                                onClick={() => {
                                  navigate("/class/lesson/list")
                                }}
                              >
                                수업나눔
                              </a>
                              <a
                                onClick={() => {
                                  navigate("/class/room/list")
                                }}
                              >
                                교실이야기
                              </a>
                            </div>
                          </div>
                        </div>
                      )

                      setVertexPos("135px")
                    }}
                  >
                    클래스
                  </button>
                  <span>|</span>
                  <button
                    id="visible"
                    onClick={() => {
                      location == "visible"
                        ? setLocation(null)
                        : setLocation("visible")

                      setInBubble(
                        <div style={{ cursor: "pointer" }}>
                          <div className={styles.classCon}>
                            <div style={{ marginLeft: "35px" }}>
                              <a style={{ width: "80px" }}></a>
                              <a style={{ width: "80px" }}></a>
                              <a
                                onClick={() => {
                                  navigate("/visible/card/list")
                                }}
                              >
                                오늘의 단어
                              </a>
                              <a style={{ width: "20px" }}></a>
                              <a
                                onClick={() => {
                                  navigate("/visible/media/list")
                                }}
                              >
                                영상
                              </a>
                            </div>
                          </div>
                        </div>
                      )

                      setVertexPos("235px")
                    }}
                  >
                    시각자료
                  </button>
                </div>

                <div>
                  <div
                    style={{ fontSize: "13px" }}
                    onClick={() => permission("/myBookshelf")}
                  >
                    <img src={fontBookShelf} alt={"paymentLogo"} />
                    나의 서재
                  </div>
                </div>
              </div>
              <div style={{ position: "relative" }}>
                {location ? (
                  <SpeechBubble
                    style={{ width: "533px", left: "-20px" }}
                    vertexPosition={{
                      left: vertexPos,
                      top: "-10px",
                      //width: "10px",
                    }}
                  >
                    {inBubble}
                  </SpeechBubble>
                ) : null}
              </div>
            </div>
            <hr />
          </div>
        </>
      )}
      <div style={{ display: "none" }} />
    </>
  )
}

export default Header

const mainColor = ["#E76B86", "#028E93", "#8EACD9", "#F79C64", "#ABC261"]
