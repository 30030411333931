import React, { useEffect, useCallback, useState } from "react"
import { resetBand, toggleband } from "store/modules/bannerReducer.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as styles from "./band.module.css"
import bannerClose from "images/bannerClose.png"
import api from "apis"
const Band = () => {
  const dispatch = useDispatch()
  const resetVandDis = useCallback(() => dispatch(resetBand()), [dispatch])
  const togleBandDis = useCallback(() => dispatch(toggleband()), [dispatch])
  const { band, bandToggle } = useSelector(
    ({ bannerReducer }) => bannerReducer,
    shallowEqual
  )

  // const [live, setLive] = useState(true)

  useEffect(() => () => resetVandDis(), [])
  return (
    <>
      {band?.banner_no && bandToggle ? (
        <div className={styles.con}>
          <div className={styles.main}>
            <div />
            <div
              className={styles.content}
              onClick={async () => {
                await api.bannerHit({
                  body: {
                    banner_number: band?.banner_no,
                  },
                })
                window.location.href = band?.link
              }}
            >
              {band.title}
            </div>
            <img
              src={bannerClose}
              className={styles.close}
              onClick={togleBandDis}
            />
          </div>
        </div>
      ) : null}
    </>
  )
}
export default Band
